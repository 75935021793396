import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from 'components/SEO'
import Footer from './Footer'

import styles from './Legal.module.scss'

export const query = graphql`
  query TermsOfServicePageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

  }
`

const TermsOfServicePage = ({ data }) => {
  const { banner, site: { siteMetadata } } = data

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/legal/`,
          "name": "Legal"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/legal/terms-of-service/`,
          "name": "Terms of Service"
        }
      },
    ]
  }

  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`Terms of Service | SloppyDeep`}
      description={`Get informations about SloppyDeep Terms & Conditions`}
      keywords={`terms of service sloppydeep, tos sloppydeep, sloppydeep tos, sloppydeep terms`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/legal/terms-of-service/`}
      robots='index, follow'
    />
    <div className={`${styles.legalScreen} screen-container`}>
      <div className={styles.documentTitle}>
        <h1>Terms of Service</h1>
        <span>updated on 13 march 2020</span>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Acceptance of the Terms</h2>
        <div className={styles.sectionContent}>
          By using or visiting www.sloppydeep.com or any content, functionality and services offered on this
          Website, you signify your agreement to these Terms of Service and our Privacy Policy, found 
          at <Link to={'/legal/privacy-policy/'}>https://www.sloppydeep.com/legal/privacy-policy/</Link>
          <ul>
            <li>
              You affirm that you are at least 18 years of age or the age of majority in the jurisdiction 
              you are accessing a Website from, and are fully able and competent to enter into the terms, 
              conditions, obligations, affirmations, representations, and warranties set forth in these 
              Terms of Service, and to abide by and comply with these Terms of Service. If you are under 18 
              or the applicable age of majority, please do not use any of the Websites. You also represent 
              that the jurisdiction from which you access a Website does not prohibit the receiving or viewing 
              of sexually explicit content.
            </li>
            <li>
              You know and understand that the materials presented on the website include explicit visual, 
              audio, and/or textual depictions of nudity and explicit sexual activities; that you are not 
              offended by such materials and that by agreeing to these Terms of Services you are warranting 
              to us that you are intentionally and knowingly seeking access to such explicit sexual materials 
              for your own personal viewing.
            </li>
            <li>
              You will use the Website solely for personal and non-commercial purposes.
            </li>
            <li>
              You will not download, copy or distribute any part of the Website for any commercial purpose.
            </li>
            <li>
              You will not alter, delete, add, change or edit any of these Terms of Services, and any such 
              attempted alteration shall be void and of no effect.
            </li>
          </ul>
          <br/>
          If you do not agree to one or more of these terms of Service or our Privacy Policy, please do not 
          access or use this Website.<br/><br/>

          We reserve the right to amend these Terms of Service at any time and 
          without notice, and it is your responsibility to review these Terms of Service for any changes. 
          Your use of a Website following any amendment of these Terms of Service will signify your assent 
          to and acceptance of its revised terms.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Accessing the Website</h2>
        <div className={styles.sectionContent}>
          We reserve the right to withdraw or amend any part of the Website, and any service or material we 
          provide on the Website, in our sole discretion without notice. We will not be liable if for any 
          reason all or any part of a Website is unavailable at any time or for any period. From time to 
          time, we may restrict access to some parts of the Website, or the entire Website to visitors.<br/><br/>

          We may block some pages or pieces of content in specified countries in order to keep our affiliate's 
          privacy secured.<br/><br/>

          You are responsible for:
          <ul>
            <li>
              making all arrangements necessary for you to have access to the Websites, and
            </li>
            <li>
              ensuring that all persons who access any of the Websites through your internet connection are 
              aware of these Terms of Service and comply with them.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Intellectual Property Rights</h2>
        <div className={styles.sectionContent}>
          The Website material are owned by or licensed by the Provider and/or its affiliates, and their 
          advertisers, licensors, suppliers, service providers, promotional partners and/or sponsors, subject 
          to copyright and other intellectual property rights under the relevant governing laws and 
          conventions. You may not copy, reproduce, distribute, broadcast, display, sell, license, or 
          otherwise exploit the Content and/or Marks for any other purposes without the prior written 
          consent of the respective owners.<br/><br/>

          The above described license is conditioned on your compliance with these Terms of Service, 
          including, specifically, your agreement to view the Website whole and intact as presented by 
          the Website host, complete with any advertising, and shall terminate upon termination of these 
          Terms of Service. If you breach any provision of these Terms of Service, any license you have 
          obtained will be automatically rescinded and terminated. In order to protect our rights some 
          Content made available on the Website may be controlled by digital rights management technologies, 
          which will restrict how you may use the Content. You must not circumvent, remove, delete, disable, 
          alter, or otherwise interfere with any digital rights management technology. Such conduct is 
          prohibited by law.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Use of Website</h2>
        <div className={styles.sectionContent}>
          You agree that you will only use the Websites and our services for the lawful purposes expressly 
          permitted and contemplated by these Terms of Service. You may not use the Website and our services 
          for any other purposes, including but not limited to commercial purposes, without our express 
          written consent.<br/><br/>

          You agree that you will view the Website and their content unaltered and unmodified. You 
          acknowledge and understand that you are prohibited from modifying the Websites or eliminating 
          any of the content of the Websites, including ads. By using the Websites, you expressly agree 
          to accept advertising served on and through the Websites and to refrain from using ad blocking 
          software or to disable ad blocking software before visiting the Websites.<br/><br/>

          Content is provided to you AS IS. You may access Content for your information and personal use 
          solely as intended through the provided functionality of the Website and as permitted under 
          these Terms of Service. You shall not download any Content unless you see a "download" or 
          similar link displayed by the Websites for that Content. You shall not copy, reproduce, 
          distribute, transmit, broadcast, display, sell, license, or otherwise exploit any Content for 
          any other purposes without the prior written consent of the Websites’ operator or the respective 
          licensors of the Content. The Websites and their licensors reserve all rights not expressly 
          granted in and to the Websites and the Content.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Prohibited Uses</h2>
        <div className={styles.sectionContent}>
          You agree that you will not use or attempt to use any method, device, software, or routine to harm 
          others or interfere with the functioning of the Website, or use and/or monitor any information in 
          or related to the Website for any unauthorized purpose.<br/><br/>

          In addition to the general restrictions above, the following restrictions and conditions apply 
          specifically to your use of Content. Any determination regarding breach of any of the following 
          is final. Please review the following list of prohibited uses carefully before using the Website. 
          Specifically, you agree not to use the Websites to:
          <ul>
            <li>
              violate any law or encourage or provide instructions to another to do so;
            </li>
            <li>
              act in a manner that negatively affects other users’ ability to use the Websites, including 
              without limitation by engaging in conduct that is harmful, threatening, abusive, inflammatory, 
              intimidating, violent or encouraging of violence to people or animals, harassing, stalking, 
              invasive of another’s privacy, or racially, ethnically, or otherwise objectionable;<br/>
              ve rise to civil liability, violate any law, or is otherwise inappropriate;
            </li>
            <li>
              deploy programs, software, or applications designed to interrupt, destroy or limit the 
              functionality of any computer software or hardware or telecommunications equipment, 
              including by engaging in any denial of service attack or similar conduct;
            </li>
            <li>
              deploy or use programs, software or applications designed to harm, interfere with the operation 
              of, or access in an unauthorized manner, services, networks, servers, or other infrastructure;
            </li>
            <li>
              exceed your authorized access to any portion of the Websites;
            </li>
            <li>
              remove, delete, alter, circumvent, avoid, or bypass any digital rights management technology, 
              encryption or security tools used anywhere on the Websites or in connection with our services;
            </li>
            <li>
              collect or store personal data about anyone;
            </li>
            <li>
              alter or modify without permission any part of the Websites or their content, including ads;
            </li>
            <li>
              obtain or attempt to access or otherwise obtain any Content or information through any means 
              not intentionally made available or provided for through the Websites;
            </li>
            <li>
              exploit errors in design, features which are not documented, and/or bugs to gain access that 
              would otherwise not be available.
            </li>
          </ul>
          <br/>
          Additionally, you agree not to:
          <ul>
            <li>
              use the Websites in any manner that could disable, overburden, damage, or impair the site or 
              interfere with any other party’s use of the Website, including their ability to engage in 
              real time activities through the Websites;
            </li>
            <li>
              use any robot, spider, or other automatic device, process, or means to access the Website for 
              any purpose, including monitoring or copying any of the material on the Website without our 
              prior written consent;
            </li>
            <li>
              use any manual process to monitor or copy any of the material on the Website or for any other 
              unauthorized purpose without our prior written consent;
            </li>
            <li>
              use any information obtained from or through the Websites to block or interfere with the 
              display of any advertising on the Website, or for the purpose of implementing, modifying or 
              updating any software or filter lists that block or interfere with the display of any 
              advertising on the Website;
            </li>
            <li>
              use any device, bots, scripts, software, or routine that interferes with the proper working 
              of the Website or that shortcut or alter Website functions to run or appear in ways that 
              are not intended by the Website's design;
            </li>
            <li>
              introduce or upload any viruses, Trojan horses, worms, logic bombs, time bombs, cancelbots, 
              corrupted files or any other similar software, program, or material which is malicious or 
              technologically harmful or that that may damage the operation of another’s property or of 
              the Website or our services;
            </li>
            <li>
              attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the 
              Website, the server on which the Website are stored, or any server, computer, or database 
              connected to the Website;
            </li>
            <li>
              attack the Websites via a denial-of-service attack or a distributed denial-of-service attack;
            </li>
            <li>
              otherwise attempt to interfere with the proper working of the Website.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>We have the rights to:</h2>
        <div className={styles.sectionContent}>
          <ul>
            <li>
              take appropriate legal action, including without limitation, referral to law enforcement, 
              for any illegal or unauthorized use of the Websites;
            </li>
            <li>
              terminate or suspend your access to all or part of the Websites for any or no reason, 
              including without limitation, any violation of these Terms of Service.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Copyrights and Other Intellectual Property</h2>
        <div className={styles.sectionContent}>
          We do not own, host, produce or upload any videos displayed on this website, we only link to them.<br/>
          If you find inappropriate content that you believe should be removed (illegal content, copyright 
          infringement) you can contact the site owner where it is hosted.<br/><br/>

          By your request we can remove thumbnails or images from our website, you can inform us
          at <a href='mailto:report@sloppydeep.com'>report@sloppydeep.com</a> and we will get back to you.<br/>
          We do our best to delete broken links, inappropriate content or copyrighted material when it is 
          reported.<br/><br/>

          All content and images are in full compliance with the requirements of 18 U.S.C. 2257 and associated 
          regulations.<br/><br/>

          If you have any further question please email us
          at <a href='mailto:contact@sloppydeep.com'>contact@sloppydeep.com</a>
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Changes to the Websites</h2>
        <div className={styles.sectionContent}>
          The Website allow others to display advertisements. These third parties use technology to deliver 
          advertisements you see using the Websites directly to your browser. In doing so, they may 
          automatically receive your IP, or "Internet Protocol", address. Others that place advertising 
          using the Websites may have the ability to use cookies and/or web beacons to collect information, 
          including information about your usage of the Websites. We do not control the processes that 
          advertisers use to collect information. However, IP addresses, cookies and web beacons alone 
          generally cannot be used to identify individuals, only machines. Therefore, advertisers and others 
          whose advertisements or content may be provided through the service generally will not know who 
          you are unless you provide additional information to them, by responding to an advertisement, by 
          entering into an agreement with them, or by some other means.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Permitted Disclosures of Personal Information</h2>
        <div className={styles.sectionContent}>
          The Website does not collect personally identifiable information (data such as your name, email 
          address) The Website will not disclose any personally identifiable information it collects or 
          obtains except if the Website is required to do so in order to comply with any valid legal 
          process or governmental request (such as a court order, search warrant, subpoena, civil discovery 
          request, or statutory requirement) and may in our sole discretion advise you of such process 
          or request.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Limitation of Liability</h2>
        <div className={styles.sectionContent}>
          IN NO EVENT SHALL THE WEBSITES, THEIR SITE OPERATOR, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, 
          EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, 
          OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF 
          CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR 
          ACCESS TO AND USE OF OUR WEBSITES OR SERVICES, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR 
          SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED 
          THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR WEBSITES, (IV) ANY 
          BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITES 
          OR SERVICES BY ANY THIRD PARTY, (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR 
          DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, 
          OR OTHERWISE MADE AVAILABLE VIA OUR WEBSITES OR SERVICES, AND/OR (VI) INTERACTIONS YOU HAVE WITH 
          THIRD-PARTY ADVERTISEMENTS OR SERVICE PROVIDERS, OR THIRD-PARTY SITES, FOUND ON OR THROUGH THE WEB 
          SITES, INCLUDING PAYMENT AND DELIVERY OF RELATED GOODS OR SERVICES, AND ANY OTHER TERMS, 
          CONDITIONS, POLICIES, WARRANTIES OR REPRESENTATIONS ASSOCIATED WITH SUCH DEALINGS, WHETHER BASED 
          ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE WEBSITES OR THEIR 
          SITE OPERTOR ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY 
          SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.<br/><br/>

          YOU SPECIFICALLY ACKNOWLEDGE THAT THE WEBSITES SHALL NOT BE LIABLE FOR CONTENT OR THE DEFAMATORY, 
          OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY, AND THAT THE RISK OF HARM OR DAMAGE FROM THE 
          FOREGOING RESTS ENTIRELY WITH YOU.<br/><br/>

          YOU FURTHER ACKNOWLEGE THAT ANY CONTENT UPLOADED TO THE SITE MAY BE VIEWED, DOWNLOADED, REPUBLISHED, 
          AND DISTRIBUTED – POTENTIALLY IN VIOLATION OF YOUR RIGHTS OR THIS AGREEMENT – AND THAT YOU ASSUME 
          SUCH RISKS AS A MATERIAL PART OF THESE TERMS OF SERVICE.<br/><br/>

          YOU AGREE NOT TO FILE ANY LAWSUIT OR PROCEEDING INCONSISTENT WITH THE FOREGOING LIABILITY 
          LIMITATIONS.<br/><br/>

          Any claim by you that may arise in connection with these Terms of Service will be compensable by 
          monetary damages, and you will in no event be entitled to injunctive or other equitable relief.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Miscellaneous</h2>
        <div className={styles.sectionContent}>
          We may terminate these Terms of Service for any or no reason at any time by notifying you through 
          a notice on the Websites, by email, or by any other method of communication. Any such termination 
          will be without prejudice to our rights, remedies, claims, or defenses hereunder. Upon termination 
          of the Terms of Service, you will no longer have a right to access to our Content.
        </div>
      </div>
      <Footer />
    </div>
    </>
  )
}

export default TermsOfServicePage
